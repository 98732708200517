import { create } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import Config from '../utils/config';
import { IUpdateSector, Sector} from '../models/sector';

@Injectable({
  providedIn: 'root',
})
export class SectorService {
  constructor(private http: HttpClient) {}

  getSectorsList(filters: any) {
    return this.http.post<{sectors: Sector[], metadata: any}>(`${Config.baseUrlV2}/sectors/list`, filters);
  }

  getSectors(): Observable<any> {
    return this.http.get<any>(`${Config.baseUrl}/mt/sectors`);
  }

  getAllSectors() {
    return this.http.get<any>(`${Config.baseUrl}/sectors`);
  }

  getAllSectorsByZoneSupervisors(zone_sup_id: number) {
    return this.http.get<any>(
      `${Config.baseUrl}/sectors/zone_sup/${zone_sup_id}`
    );
  }

  getSectorsForecasts(filter: any) {
    return this.http.post<any>(
      `${Config.baseUrl}/get_forecasts_by_sectors_by_date`,
      filter
    );
  }

  // Get forecast for sector by date separated by tournee
  getSectorsForecastsDetails(filter: {sector_id: number, date: string}) {
    return this.http.post<any>(
      `${Config.baseUrl}/sector_forecasts_grouped_by_tournee`,
      filter
    );
  }

  getSectorAndGoals(data: any) {
    return this.http.get<any>(`${Config.baseUrlV2}/sector_goals`, {
      params: data,
    });
  }

  getSectorsByShiftSupervisor() {
    return this.http.post<any>(
      `${Config.baseUrlV2}/sectors_by_shift_supervisor`,
      {}
    );
  }

  getAllSectorsV2() {
    return this.http.get<any>(
      `${Config.baseUrlV2}/sectors`,
      {}
    );
  }

  findOneById(id: number) {
    return this.http.get<any>(`${Config.baseUrlV2}/sectors/${id}`);
  }

  updateSector(payload: IUpdateSector , id: number) {
    return this.http.put<any>(`${Config.baseUrlV2}/sectors/${id}/edit`, payload);
  }

  importCoordinatesData(data: any) {
    return this.http.post<any>(`${Config.baseUrlV2}/import_sector_coordinates`, data);
  }

  createSector(data: Sector) {
    return this.http.post<any>(`${Config.baseUrlV2}/sectors`, data);
  }
}
