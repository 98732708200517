import { IconName, IconNamesEnum } from 'ngx-bootstrap-icons';
import { Permission } from '../models/permission';
import {
  EVehicleStatus,
  EVehicleStatusFR,
  EVehicleType,
  EVehicleTypeFR,
} from '../interfaces/vehicle-interface';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ESaleStatus, ESaleStatusFR } from '../models/sales';
import * as XLSX from 'xlsx';

export const buildParams = (params: any): string => {
  const urlParams =
    params !== undefined
      ? '?' +
      Object.keys(params)
        .map(function (key) {
          return key + '=' + params[key];
        })
        .join('&')
      : '';

  return urlParams;
};

export const DAYSOFWEEK = [
  { id: 1, label: 'Lundi' },
  { id: 2, label: 'Mardi' },
  { id: 3, label: 'Mercredi' },
  { id: 4, label: 'Jeudi' },
  { id: 5, label: 'Vendredi' },
  { id: 6, label: 'Samedi' },
  { id: 7, label: 'Dimanche' },
];

export const DELIVERYDAYS = [
  { id: 1, label: 'Lundi' },
  { id: 2, label: 'Mardi' },
  { id: 3, label: 'Mercredi' },
  { id: 4, label: 'Jeudi' },
  { id: 5, label: 'Vendredi' },
  { id: 6, label: 'Samedi' },
  { id: 8, label: 'Dimanche' },
];

export const YEARS: { value: number; label: string }[] = [
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
  { value: 2025, label: '2025' },
];

export const THREE_DELIVERY_DAYS = [
  { id: 'Monday', label: 'Lundi' },
  { id: 'Tuesday', label: 'Mardi' },
  { id: 'Wednesday', label: 'Mercredi' },
  { id: 'Thursday', label: 'Jeudi' },
  { id: 'Friday', label: 'Vendredi' },
  { id: 'Saturday', label: 'Samedi' },
  { id: 'Sunday', label: 'Dimanche' },
];

export const SECOND_DELIVERY_DAYS = [
  { id: '_Monday', label: 'Lundi' },
  { id: '_Tuesday', label: 'Mardi' },
  { id: '_Wednesday', label: 'Mercredi' },
  { id: '_Thursday', label: 'Jeudi' },
  { id: '_Friday', label: 'Vendredi' },
  { id: '_Saturday', label: 'Samedi' },
  { id: '_Sunday', label: 'Dimanche' },
];

export function sortArray(
  arrayToSort: any[],
  prop: string,
  isAsc: boolean = true
) {
  return arrayToSort.sort((prevElement: any, nextElement: any) => {
    let result = 0;
    const prevValue = prevElement[prop];
    const nextValue = nextElement[prop];
    result = (prevValue < nextValue ? -1 : 1) * (isAsc ? 1 : -1);
    return result;
  });
}

export function getDiffirenceBetweenArray(
  arr1: any,
  arr2: any,
  criteria: string
) {
  return arr1.filter(
    (arr1Value: any) =>
      !arr2.some(
        (arr2Value: any) => arr1Value[criteria] === arr2Value[criteria]
      )
  );
}

export enum EColumnType {
  ACTION = 'action',
  TEXT = 'text',
  TOOLTIPS = 'tooltips',
  STATUS = 'status',
  SWITCH = 'switch',
  MONEY = 'money',
  NUMBER = 'number',
  TEMPLATE = 'template',
  DATE = 'date',
}

export interface ActionEventType {
  action: ActionColumnDef;
  rowData: any;
}

export interface ActionColumnDef {
  icon?: IconNamesEnum | IconName;
  text?: string;
  functionToInvoque: string;
  baseUrl?: string;
  isToogleButton?: boolean; // Pour savoir si c'est un bouton qui change suivant un context
  /* Pour savoir l'index du bouton sur lequel il faut mettre la couleur exist si isToogleButton = true */
  actionToogleableIndex?: number;
  /* Pour la couleur des boutons d'action exist si isToogleButton = true */
  contextActionStyle?: { positifStyle: any; negatifStyle: any };
  contextActionText?: { positif: string; negatif: string };
  /* La propriété à vérifier suivant le context qui est boolean */
  contextualPropertyToCheck?: string;
  class?: string;
  permissions?: string | string[];
}

export class ColumnDef {
  label?: string;
  name: string;
  type: EColumnType;
  clickable: boolean;
  hearderPositionClass?: string[];
  positionClass: string[];
  parent: string;
  currency: string;
  actions: ActionColumnDef[];
  cellStyle: any;
  renderTemplate: any;
  cellTextStyle?: string[];

  constructor(
    options: {
      label?: string;
      name?: string;
      type?: EColumnType;
      clickable?: boolean;
      hearderPositionClass?: string[];
      positionClass?: string[];
      parent?: string;
      currency?: string;
      actions?: ActionColumnDef[];
      cellStyle?: any;
      renderTemplate?: any;
      cellTextStyle?: string[];
    } = {}
  ) {
    (this.label = options.label),
      (this.name = options.name || ''),
      (this.type = options.type || EColumnType.TEXT),
      (this.clickable = options.clickable || false),
      (this.positionClass = options.positionClass || []);
    this.hearderPositionClass = options.hearderPositionClass || [];
    this.parent = options.parent || '';
    this.currency = options.currency || '';
    this.actions = options.actions || [];
    this.cellStyle = options.cellStyle || {};
    this.renderTemplate = options.renderTemplate || null;
    this.cellTextStyle = options.cellTextStyle || [];
  }
}

// export function formatDate(inputDate: string): string {
//   const [year, month, day] = inputDate.split('-');
//   return `${year}-${month}-${day}`;
// }

export function formatDate(inputDate: string | null | undefined): string {
  if (typeof inputDate !== 'string') {
    console.error('Invalid inputDate:', inputDate);
    return '';
  }
  const [year, month, day] = inputDate.split('-');
  if (!year || !month || !day) {
    console.error('Invalid date format:', inputDate);
    return '';
  }
  return `${year}-${month}-${day}`;
}


export function formatDateFR(inputDate: string): string {
  const [year, month, day] = inputDate.split('-');
  return `${day}-${month}-${year}`;
}
export function dateAdapter(dateToFormat: Date): string {
  const day = dateToFormat.getDate().toString().padStart(2, '0');
  const month = (dateToFormat.getMonth() + 1).toString().padStart(2, '0');
  const year = dateToFormat.getFullYear().toString();
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const selectedDate = new Date(control.value);
    if (selectedDate <= minDate) {
      return { minDate: true };
    }
    return null;
  };
}

export function askedValidatorFactory(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    // Vérifiez si la valeur contient un 'e' ou n'est pas numérique ou est négative
    if (/[eE]/.test(value) || isNaN(Number(value)) || Number(value) < 0) {
      return { invalidAsked: true };
    }
    // La validation a réussi
    return null;
  };
}

export function minMaxValidator(
  minControl: string,
  maxControl: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minValue = control.get(minControl);
    const maxValue = control.get(maxControl);
    if (maxValue?.value > 0) {
      if (minValue?.value > maxValue?.value) {
        maxValue?.setErrors({ minMax: true });
      } else {
        maxValue?.setErrors(null);
      }
    } else return null;
    return null;
  };
}

export function isDateTime1AfterDateTime2(
  dateTimeStr1: string,
  dateTimeStr2: string
) {
  const dateTime1 = new Date(dateTimeStr1);
  const dateTime2 = new Date(dateTimeStr2);
  return dateTime1 >= dateTime2;
}

//Help auto adapter text-color from background color
export function getContrastColorBackgroungAndText(backgroundColor: string) {
  // Convertir la couleur hexadécimale en valeurs RGB
  let r = parseInt(backgroundColor.slice(1, 3), 16);
  let g = parseInt(backgroundColor.slice(3, 5), 16);
  let b = parseInt(backgroundColor.slice(5, 7), 16);

  // Calculer la luminosité relative du fond
  let brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Choisir la couleur du texte en fonction de la luminosité du fond
  return brightness > 128 ? '#000000' : '#FFFFFF';
}

export interface MenuItem {
  url: string;
  icon: IconNamesEnum | IconName;
  title: string;
  authorizedRole: string[];
  routerLinkActiveOptions: boolean;
  children?: MenuItem[];
  collapsedId?: string;
}

export enum EUserFormType {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export enum ESellerRefFormType {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
}

export interface PermissionAssignmentInterface {
  permissionsToUnAssign: Permission[];
  permissionsToAssign: Permission[];
}

export enum ECustomPointOfSaleFilter {
  NOTVISITEDLAST8DAYS = 'NOTVISITEDLAST8DAYS',
  NEWPOINTOFSALE = 'NEWPOINTOFSALE',
  TODEACTIVATE = 'TODEACTIVATE',
  ALL = 'ALL',
}

export enum ETransactionType {
  'WITHDRAWAL' = 'Retrait',
  'DEPOSIT' = 'Depot',
  'TRANSFERT' = 'Transfert',
  'COMMERCIALPAYMENT' = 'Paiement Marchand',
  'INVOICE' = 'Paiement Facture',
  'ALL' = '',
}

export const formattedDateForCSV = (accountingDate: Date) => {
  const date = new Date(accountingDate);

  return (
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    date.getDate().toString().padStart(2, '0')
  );
};

export const isValidIntervallDate = (startDate: Date, endDate: Date) =>
  endDate >= startDate;

export function isDateWithinLastGivenNumberOfDays(
  date: string | Date,
  numberOfDayAgo: number
) {
  // Convertir la date fournie en objet Date
  const givenDate = dateAdapter(new Date(date));
  const inputDate = new Date(givenDate);

  // Obtenir la date d'aujourd'hui
  const today = new Date();

  // Définir la date il y a 30 jours à partir d'aujourd'hui
  const daysAgo = new Date();
  daysAgo.setDate(today.getDate() - numberOfDayAgo);

  // Comparer les dates
  return inputDate >= daysAgo && inputDate <= today;
}

export enum ProductState {
  CLAIM = 'CLAIM',
  DECLASSED = 'DECLASSED',
  INTACT = 'INTACT',
  MISSING = 'MISSING',
  PERCED = 'PERCED',
}

export enum EPppedProductState {
  CLAIM = 'CLAIM',
  DECLASSED = 'DECLASSED',
  MISSING = 'MISSING',
  PERCED = 'PERCED',
}

export enum EProductStateFr {
  CLAIM = 'Réclamation',
  DECLASSED = 'Déclassé',
  MISSING = 'Manquant',
  PERCED = 'Percé',
  INTACT = 'Intact',
}

export enum EPppedProductStateFr {
  CLAIM = 'Réclamation',
  DECLASSED = 'Déclassé',
  MISSING = 'Manquant',
  PERCED = 'Percé',
}

export const translatePped = (Pped: keyof typeof EPppedProductState) => {
  return EPppedProductStateFr[Pped];
};

export const translateProductReturnType = (
  Pped: keyof typeof EProductReturnType
) => {
  return EProductReturnTypeFR[Pped];
};

export const translateTrucksStatus = (status: keyof typeof EVehicleStatus) => {
  return EVehicleStatusFR[status];
};

export const translateVehicleType = (status: keyof typeof EVehicleType) => {
  return EVehicleTypeFR[status];
};

export const translateMotifNoSales = (motif: keyof typeof EMotifNoSale) => {
  return EMotifNoSaleFR[motif];
};

export const translateRefrigeratorType = (
  status: keyof typeof ERefrigeratorType
) => {
  return ERefrigeratorTypeFR[status];
};

export const translateCategoryCluster = (
  status: keyof typeof ECategoryCluster
) => {
  return ECategoryClusterFR[status];
};

export const translateSaleStatus = (status: keyof typeof ESaleStatus) => {
  return ESaleStatusFR[status];
};

export const translateUpdateTourneeOption = (
  option: keyof typeof EUpdateTournee
) => {
  return EUpdateTourneeFR[option];
};

export const translateGenericStatus = (
  status: keyof typeof EGenericStatus
) => {
  return EGenericStatusFrV2[status];
};

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

/* Cdk formarray */
export interface IFormArrayCdkStep {
  label: string;
  formArrayName: string;
  editable: boolean;
  formArrayGetter: any;
  state: ProductState | EntityInventoryState;
}

/* Form mode */
export enum EFormMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum EModalFrameDeliveryPoint {
  FRAME_INFOS = 'FRAME_INFOS',
  PRODUCTS = 'PRODUCTS',
}

export enum EModalFramePointOfSale {
  FRAME_INFOS = 'FRAME_INFOS',
  SECTOR_ZONE = 'SECTOR_ZONE',
  PASSAGE_DAY = 'PASSAGE_DAY',
}

export enum EUserRole {
  SHIFT_SUPERVISOR = 'chef de quart',
  ADMIN = 'admin',
  SUPERVISOR = 'superviseur',
  BUSINESS_DEVELOPPER = 'Business Developper',
  DRIVER = 'Chauffeur',
  CONVEYOR = 'Convoyeur',
  SELLER = 'vendeur',
  DISTRIBUTOR = 'Distributer',
  COUNTRY_MANAGER = 'Country Manager',
  AN_ACCOUNTONT = 'Comptable',
  DELIVERY_MAN = 'Livreur',
  PRE_COMMANDER = 'Précommandeur',
  ZONE_CHIEF = 'chef de zone',
  LOGISTIC_MANAGER = 'Responsable logistique',
  SUPERVIOR_LOGISTIC = 'Superviseur Logistique',
  PLANNER = 'Planificateur',
}

export enum EGenericStatus {
  Validate = 'Validate',
  Devalidate = 'Devalidate',
}

export enum EGenericStatusFr {
  Validate = 'validé',
  Devalidate = 'Dévalidé',
}

export enum EGenericStatusFrV2 {
  Validate = 'Validé',
  Devalidate = 'Non Validé',
}


export enum ERefrigeratorType {
  DOUBLE_DOOR = 'DOUBLE_DOOR',
  SINGLE_DOOR = 'SINGLE_DOOR',
  NO_FRIDGE = 'NO_FRIDGE',
}

export enum ERefrigeratorTypeFR {
  DOUBLE_DOOR = 'Double portes',
  SINGLE_DOOR = 'Porte simple',
  NO_FRIDGE = 'Pas de frigo',
}

export enum ECategoryCluster {
  TURNOVER = 'TURNOVER',
}

export enum ECategoryClusterFR {
  TURNOVER = "Chiffre d'affaire",
}

export enum EClusterType {
  GOLDEN = 'GOLDEN',
  DIAMOND = 'DIAMOND',
  CLASSIC = 'CLASSIC',
}

export enum ECategoryPointOFSale {
  A = 'A',
  A_PLUS = 'A_PLUS',
  TRADI = 'TRADI',
  WHOLESALER = 'WHOLESALER',
  FOODSERVICE = 'FOODSERVICE',
  SALES_TO_INDIVIDUALS = 'Sales to individuals',
}

export enum EWeeklyDayPassage {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export enum EWeeklyDayPassageFR {
  monday = 'Lundi',
  tuesday = 'Mardi',
  wednesday = 'Mercredi',
  thursday = 'Jeudi',
  friday = 'Vendredi',
  saturday = 'Samedi',
}

export enum EStatus {
  Activate = 'Activate',
  Deactivate = 'Deactivate',
}

export enum EStatusFR {
  Activate = 'Actif',
  Deactivate = 'Inactif',
}

export enum EPaiementMode {
  CREDIT = 'CREDIT',
  COMPTANT = 'COMPTANT',
}

export enum EProductReturnType {
  declass = 'declass',
  pierced = 'pierced',
  claim = 'claim',
  missing = 'missing',
}

export enum EProductReturnTypeFR {
  declass = 'Déclassé',
  pierced = 'Percé',
  claim = 'Réclamation',
  missing = 'Manquant',
}

export enum EMotifNoSale {
  'no money' = 'no money',
  'inventory' = 'inventory',
  'too much stock' = 'too much stock',
  'Fridge Breakdown' = 'Fridge Breakdown',
  'Point of sale to deactivate' = 'Point of sale to deactivate',
  'Absence of the shopkeeper' = 'Absence of the shopkeeper',
}

export enum EMotifNoSaleFR {
  'no money' = "Pas d'argent",
  'inventory' = 'En inventaire',
  'too much stock' = 'Trop de stock',
  'Fridge Breakdown' = 'Panne Frigo',
  'Point of sale to deactivate' = 'Point de vente à désactiver',
  'Absence of the shopkeeper' = 'Absence du boutiquier',
}

export const calculatePercentage = (value: number, total: number) => {
  const percentage = value > 0 ? (value * 100) / total : 0;
  const roundedPercentage = Math.ceil(percentage);
  return roundedPercentage;
};

export enum ETranslateDayFromFrToEn {
  lundi = 'monday',
  mardi = 'tuesday',
  mercredi = 'wednesday',
  jeudi = 'thursday',
  vendredi = 'friday',
  samedi = 'saturday',
  dimanche = 'sunday',
}

export enum EUpdateTournee {
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
  ADDITIONNAL_LOADING = 'ADDITIONNAL_LOADING',
  MOVEMENT_OF_STOCK = 'MOVEMENT_OF_STOCK',
}

export enum EUpdateTourneeFR {
  LOADING = 'Chargement',
  UNLOADING = 'Déchargement',
  ADDITIONNAL_LOADING = 'Chargement supplémentaire',
  MOVEMENT_OF_STOCK = 'Mouvement de stocks',
}

export enum EQuantityUnloading {
  quantity_claim = 'quantity_claim',
  quantity_declass = 'quantity_declass',
  quantity_missing = 'quantity_missing',
  quantity_pierced = 'quantity_pierced',
  quantity_not_sale = 'quantity_not_sale',
}


export const DeliveryDays = [
  { day_en: 'monday', day_fr: 'lundi' },
  { day_en: 'tuesday', day_fr: 'mardi' },
  { day_en: 'wednesday', day_fr: 'mercredi' },
  { day_en: 'thursday', day_fr: 'jeudi' },
  { day_en: 'friday', day_fr: 'vendredi' },
  { day_en: 'saturday', day_fr: 'samedi' },
  { day_en: 'sunday', day_fr: 'dimanche' },
];

import { Options } from 'ngx-qrcode-styling';
import { EntityInventoryState } from '../interfaces/inventory-record-interface';

export const qrcodeConfig: Options = {
  width: 300,
  height: 300,
  data: '',
  margin: 5,
  // dotsOptions: {
  //   color: '#000',
  //   type: 'dots',
  // },
  backgroundOptions: {
    color: '#ffffff',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 0,
  },
};

export interface IModalStyle {
  width?: string;
  height?: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  bgColor?: string;
  modalTitleClass?: string;
  padding?: string;
  modalBodyPadding?: string;
  modalHeaderPadding?: string;
  maxHeight?: string;
  borderRadius?: string;
}

//import coordinates
export function extractDataFromExcel(fileData: any): any[] {
  const data = new Uint8Array(fileData);
  const workbook = XLSX.read(data, { type: 'array' });
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json(worksheet) as any[];
}

export function isValidCoordinate(latitude: number, longitude: number): boolean {
  return !isNaN(latitude) && !isNaN(longitude) && latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180;
}


export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;;

