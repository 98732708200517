import { ColumnDef, EColumnType, EPppedProductState, ProductState } from '../utils';
import { PaginationInterface } from './pagination-interface';

export class StockMovementPaginationInterface implements PaginationInterface {
  page: number = 1;
  limit: number = 25;
  next?: number | null;
  last?: number | null;
  pages?: number | null;
  direction?: EStockMovementDirection;
  movement_type?: EStockMovementType;
  interval_of_date?: IIntervalOfDate;
  depot_id?: number;
}

export interface IIntervalOfDate {
  start_date: Date;
  end_date: Date;
}
export interface INewStockMovement {
  direction: EStockMovementDirection;
  movement_type: EStockMovementType;
  products: any;
  catalog_id?: number;
  origin?: number;
  recipient?: number;
}

export interface IStockDirectionHelper {
  label: EStockMovementDirectionFr;
  value: EStockMovementDirection;
}

export interface IStockMovementTypeHelper {
  label: EStockMovementTypeFR;
  value: EStockMovementType;
}

export enum EStockMovementDirection {
  IN = 'IN',
  OUT = 'OUT',
  INTERNAL = 'INTERNAL'
}

export enum EStockMovementDirectionFr {
  IN = 'Entrées',
  OUT = 'Sorties',
  INTERNAL = 'Interne'
}

export enum EStockMovementType {
  SALE = 'SALE',
  DONATION = 'DONATION',
  TRANSFERT = 'TRANSFERT',
  PPEDOUTING = 'PPEDOUTING',
  INTERNAL = 'INTERNAL',
  LOSS = 'LOSS'
}

export enum EStockMovementTypeFR {
  SALE = 'Vente',
  DONATION = 'Don',
  TRANSFERT = 'Transfert',
  PPEDOUTING = 'Sortie PPED',
  INTERNAL = 'PPED chambre froide',
  LOSS = 'Perte'
}

export enum ENewStockMovementQueryParam {
  SALE = 'sale',
  DONATION = 'donation',
  ENTRY = 'entry',
  OUTLET = 'outlet',
}

export interface EErrorCheckStock {
  sku_sage: string;
  asked: number;
  currentStock: number;
  state: EPppedProductState | ProductState;
}
export const ERRORTABLECOLUMNDEF = [
  new ColumnDef({
    label: 'Produit',
    name: 'sku_sage',
  }),
  new ColumnDef({
    label: 'Qte. demandée',
    name: 'asked',
    type: EColumnType.NUMBER,
    hearderPositionClass: ['text-center'],
    positionClass: ['text-center'],
  }),
  new ColumnDef({
    label: 'Qte. en stock',
    name: 'current_stock',
    type: EColumnType.NUMBER,
    hearderPositionClass: ['text-center'],
    positionClass: ['text-center'],
  }),
  new ColumnDef({
    label: 'Etat',
    name: 'state',
    hearderPositionClass: ['text-center'],
    positionClass: ['text-center'],
  }),
];
